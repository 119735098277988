.main {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    position: relative;
    font-size: 10em;
    //color: $color_dark_gray
    //color: rgba(0,255,0,.3)
    color: rgba(0,0,255,.3);
    line-height: 1;
    text-shadow: 0 0 10px #000;
    display: flex;
    justify-content: center;
    &:before {
      content: "Matrix Cloud";
      position: absolute;
      top: -10px;
      color: rgba(255,0,0,.3); }
    &:after {
      content: "Matrix Cloud";
      position: absolute;
      color: #fff;
      top: -5px; } }


  h2 {
    margin-top: 5vh;
    font-size: 1.5em;
    color: $color_yellow;
    text-transform: capitalize; } }
