/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  /**/ }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  /**/ }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  /**/
  h1 {
    font-size: 6em !important; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  /**/
  h1 {
    font-size: 3em !important; } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
  /**/ }

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  /**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  /* */ }

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  /**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  /* */ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  /**/ }

