body {
  font-size: 100%;
  background: url(../img/bg.jpg) no-repeat 0px 0px;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  font-family: Exo2Bold; }

h1,h2,h3,h4,h5,h6,a,p {
  margin: 0; }
